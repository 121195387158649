<template>
  <div class="dashboard-card bg-white rounded-sm">
    <div
      v-if="type === 'approved'"
      class="dashboard-card-bidding-time bg-secondary text-white text-caption"
    >
      <div class="p-2 d-flex justify-content-between">
        <span>Bidding ends in</span>

        <span>{{ biddingTime }}</span>
      </div>
    </div>

    <!-- Content -->

    <div class="p-2">
      <p class="m-0 text-caption text-gray">RFQ NO.{{ data.id | rfqFormat }}</p>

      <!-- Project Name -->

      <h5 class="font-style-primary text-truncate">
        {{ data.project_name }}
      </h5>

      <!-- Supplier -->

      <div v-if="type === 'quotation'" class="d-flex align-items-center">
        <div class="mr-2">
          <AppMediaViewer
            :src="
              data.user.information.profile_picture
                ? data.user.information.profile_picture
                : ''
            "
            aspectRatio="1/1"
            width="32p"
            class="rounded-circle"
          ></AppMediaViewer>
        </div>

        <div>
          <span class="d-block text-caption text-gray">SUPPLIER</span>

          <span class="font-weight-6 mr-2">
            {{ data.user.information | userFullName }}
          </span>

          <span class="text-gray text-truncate">
            {{ data.user.information | userCompany }}
          </span>
        </div>
      </div>

      <!-- Order Details -->

      <div class="dashboard-card-order row mt-3">
        <!-- RFQ Date -->

        <div class="col-6">
          <AppTextLabeled label="Rfq Date">
            <span class="dashboard-card-date font-weight-bold">
              {{ data.created_at | readableDate }}
            </span>

            <span class="dashboard-card-time ml-1 font-weight-normal">
              {{ data.created_at | readableTime }}
            </span>
          </AppTextLabeled>
        </div>

        <!-- End Bidding Date -->
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <AppTextLabeled label="END BIDDING DATE">
            <span class="dashboard-card-date font-weight-bold">
              {{ data.bidding_end_date | readableDate(false, true) }}
            </span>

            <span class="dashboard-card-time ml-1 font-weight-normal">
              {{ data.bidding_end_date | readableTime(true) }}
            </span>
          </AppTextLabeled>
        </div>

        <div class="col-6">
          <AppTextLabeled label="DELIVERY DATE">
            <span class="dashboard-card-date font-weight-bold">
              {{ data.delivery_date || readableDate(false, true) }}
            </span>
          </AppTextLabeled>
        </div>
      </div>

      <!-- Items -->

      <div class="dashboard-card-items mt-3">
        <p class="dashboard-card-label m-0">ITEMS ({{ items.length }})</p>

        <div class="row ml-n1 mr-n1">
          <div
            v-for="item in items"
            :key="`item-img-${item.id}`"
            class="col-1 p-1"
          >
            <AppMediaViewer :files="itemFiles(item)" class="w-100 h-100" />
          </div>
        </div>
      </div>
    </div>

    <!-- Actions -->

    <div class="dashboard-card-action w-100">
      <!-- request -->

      <router-link
        v-if="type === 'request'"
        class="
          dashboard-card-action-request
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-info text-decoration-none
        "
        :to="{ name: 'OrderView', params: { id: data.id } }"
      >
        <AppIcon name="document"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal">View</label>
      </router-link>

      <!-- pending -->

      <router-link
        v-else-if="type === 'approved'"
        class="
          dashboard-card-action-pending
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-info
        "
        :to="{ name: 'QuoteRequestList', params: { id: data.id } }"
      >
        <AppIcon name="document"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal">
          Quotations ({{ data.quotations_count }})
        </label>

        <label class="m-0 ml-auto font-weight-normal">View</label>
      </router-link>

      <!-- approved -->

      <router-link
        v-else-if="type === 'quotation'"
        class="
          dashboard-card-action-approved
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-success
        "
        :to="{ name: 'QuoteView', params: { id: data.quotations[0].id } }"
      >
        <AppIcon name="checkmark"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal"> Quote Approved </label>

        <label class="m-0 ml-auto font-weight-normal">View</label>
      </router-link>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import _readableDateTime from '@/shared/mixins/_readableDateTime';

import _rfqFormat from '@/shared/mixins/_rfqFormat';

import _countdownConfigs from '@/shared/mixins/_countdownConfigs';

import AppIcon from '../../../shared/elements/AppIcon.vue';

import AppTextLabeled from '../../../shared/elements/AppTextLabeled.vue';

import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';

export default {
  name: 'BuyerDashboardCard',

  components: { AppIcon, AppTextLabeled, AppMediaViewer },

  mixins: [_readableDateTime, _rfqFormat, _countdownConfigs],

  props: {
    id: { type: [String, Number, Boolean], default: false },

    rqf: { type: [String, Number], default: 0 },

    buyer: { type: String, default: '' },

    buyer_img: { type: String, default: '' },

    company: { type: String, default: '' },

    project: { type: String, default: '' },

    order_date: { type: String, default: '' },

    end_date: { type: String, default: '' },

    delivery_date: { type: String, default: '' },

    items: { type: Array, default: () => [] },

    type: {
      type: String,

      default: 'request',

      validator: (value) =>
        ['request', 'quotation', 'approved'].indexOf(value) !== -1,
    },

    data: { type: Object, default: () => ({}) },
  },

  computed: {
    dateNow() {
      return DateTime.now();
    },

    biddingTime() {
      if (this.countdownDisplayRaw.valueOf() > 0) {
        return `${this.countdownDisplayRaw.toFormat('hhHH : mm')}mm`;
      } else {
        return 'END DATE REACHED';
      }
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },

    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    startBiddingTime(endDate) {
      if (endDate) {
        this.end = DateTime.fromFormat(endDate, 'yyyy-MM-dd HH:mm:ss');
      }

      this.startTick();
    },
    itemFiles(item) {
      return item?.product?.files ? item.product.files : [];
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.type === 'approved') {
        this.startBiddingTime(this.data.bidding_end_date);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_font';

@import '@/scss/theme/_colors';

.dashboard-card {
  &-label {
    @extend .text-small;

    @extend .text-gray-dark-3;
  }

  &-date {
    @extend .text-body-2;
  }

  &-time {
    @extend .text-body-2;

    @extend .text-gray-dark-4;
  }

  &-action {
    * {
      cursor: pointer;
    }

    &-request,
    &-pending,
    &-approved {
      transition: background-color 0.3s;

      @extend .bg-gray-light-4;

      &:hover {
        @extend .bg-info-light-5;
      }
    }

    &-request {
      overflow: hidden;

      max-height: 0;

      opacity: 0;

      transition: max-height 0.3s, opacity 0.3s, background-color 0.3s;
    }
  }

  &-bidding-time {
    padding: 0;

    max-height: 0;

    transition: max-height 0.3s;

    &:hover {
      max-height: 50px;
    }
  }

  &:hover {
    .dashboard-card {
      &-action-request {
        max-height: 40px;

        opacity: 1;
      }

      &-bidding-time {
        max-height: 50px;
      }
    }
  }
}
</style>
