<template>
  <LayoutBuyer>
    <template v-slot:title>{{ title | toTitleCase }}</template>
    <div class="dashboard p-2 pl-md-5 pr-md-5 pt-3">
      <AppLoading fillSpace v-if="localLoading"></AppLoading>
      <div v-else class="d-flex flex-column h-100 w-100">
        <div class="dashboard-card-holders row">
          <!-- New Quotation Request -->
          <div class="col-12 mb-3">
            <DashboardNotification
              v-model="newNotif.show"
              class="shadow-100"
              :goTo="newNotif.goTo"
            >
              <template v-slot:title>{{ newNotif.title }}</template>
              <template v-slot:message
                ><p v-html="newNotif.message"></p
              ></template>
            </DashboardNotification>
          </div>
        </div>
        <div class="dashboard-card-holder-container w-100 overflow-hidden">
          <div
            class="
              dashboard-card-holder-container-outer
              h-100
              w-100
              flex-shrink-1 flex-shrink-md-0
            "
          >
            <div
              class="dashboard-card-holder-container-inner row overflow-hidden"
            >
              <!-- Pending RFQs -->
              <div class="col-4 h-100">
                <DashboardCardHolder
                  type="request"
                  :seeAllRoute="{ name: 'OrderRequestList' }"
                  :counter="pendingRfqs ? pendingRfqs.total : 0"
                  :items="pendingRfqs"
                ></DashboardCardHolder>
              </div>
              <!-- Received Quotes -->
              <div class="col-4 h-100">
                <DashboardCardHolder
                  type="approved"
                  :counter="receivedQuotes ? receivedQuotes.total : 0"
                  :items="receivedQuotes"
                  :seeAllRoute="{ name: 'OrderApproveList' }"
                >
                </DashboardCardHolder>
              </div>
              <!-- Approved Request -->
              <div class="col-4 h-100">
                <DashboardCardHolder
                  type="quotation"
                  :counter="approvedQuotations ? approvedQuotations.total : 0"
                  :items="approvedQuotations"
                  :seeAllRoute="{ name: 'OrderAcceptedList' }"
                >
                </DashboardCardHolder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutBuyer>
</template>

<script>
import LayoutBuyer from '@/components/Partial/Layouts/LayoutBuyer/LayoutBuyer.vue';
import DashboardCardHolder from '@/components/Buyer/Dashboard/DashboardCardHolder.vue';
import DashboardNotification from '@/components/Partial/Dashboard/DashboardNotification.vue';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import Notifications from '@/shared/api/Notifications';
import _strings from '@/shared/mixins/_strings';
import { isRoleAllowed } from '@/router/AuthorizationGate';
import { group_names } from '@/shared/constants/PersonaConstants';

export default {
  name: 'BuyerDashboard',

  components: {
    LayoutBuyer,
    DashboardNotification,
    DashboardCardHolder,
    AppLoading,
  },

  mixins: [_appApiHelper, _strings],

  props: {
    title: { type: String, default: 'Dashboard' },
  },

  data() {
    return {
      localLoading: false,
      pendingRfqs: {},
      receivedQuotes: {},
      approvedQuotations: {},
      newNotif: {
        show: false,
        title: '',
        message: '',
        goTo: '',
      },
    };
  },

  methods: {
    async getOrders(status) {
      let params = { status, per_page: 4, order_created_at: 'desc', page: 1 };

      const { owner, admin } = group_names;

      if (isRoleAllowed([owner, admin])) {
        params.origin = 0;
        params.fetch_by_company = 1;
      }

      if (status === STATUS.REQUEST_APPROVED.value) {
        params.origin = 1;
        params.with_quote_count = true;
      }

      if (status === STATUS.QUOTE_SENT.value) {
        /**
         * The status in the backend is not quote_sent for orders with quote
         * It remains are approved so we need these 2 parameters
         */
        params.origin = 2;
        params.or_without_quotations = 1;
        params.status = STATUS.REQUEST_APPROVED.value;
        params.quotation_status = 'submitted';
        params.with_quote_count = true;
      }

      if (status === STATUS.QUOTE_APPROVED.value) {
        params.origin = 3;
        params.quotation_status = 'approved';
        params.with_quote_count = true;
      }

      if (status === STATUS.REQUEST_DECLINED.value) {
        params.origin = 4;
        params.exclude_quoted = true;
      }

      const response = await Orders.getOrders({
        params,
      });

      if (this.error) {
        alert('Error retrieving data. Please try again later');
        return null;
      } else {
        return response.data;
      }
    },
    // async getQuotes(status = false) {
    //   let params = {
    //     status,
    //     per_page: 4,
    //     fetch_by_company: true,
    //     order_created_at: 'desc',
    //   };

    //   if (status === STATUS.QUOTE_APPROVED.quote_value) {
    //     params.approved_only = 1;
    //     delete params.fetch_by_company;
    //     delete params.status;
    //   } else {
    //     params.submitted_only = true;
    //   }

    //   const response = await Orders.getQuotes({ params });
    //   if (this.error) {
    //     alert('Error retrieving data. Please try again later');
    //     return null;
    //   } else {
    //     return response.data;
    //   }
    // },
    async getNotif() {
      const response = await Notifications.getNotifications({
        params: { page: 1 },
      });
      if (response.status === 200) {
        const { data } = response;
        if (data.data.length === 0) {
          return;
        }

        const notif = data.data[0];
        this.newNotif = { ...notif };

        const notifTitle = notif?.title?.toLowerCase();
        if (notifTitle.includes('new rfq')) {
          this.newNotif.goTo = { name: 'OrderAdminApprovalList' };
        } else if (notifTitle.includes('rfq approved')) {
          this.newNotif.goTo = { name: 'OrderQuotedList' };
        } else if (notifTitle.includes('quotation approved')) {
          this.newNotif.goTo = { name: 'QuoteApprovedList' };
        }

        this.newNotif.show = true;
      }
    },
  },

  async mounted() {
    this.localLoading = true;
    this.pendingRfqs = await this.getOrders(STATUS.REQUEST_PENDING.value);
    this.receivedQuotes = await this.getOrders(STATUS.QUOTE_SENT.value);
    this.approvedQuotations = await this.getOrders(STATUS.QUOTE_APPROVED.value);

    await this.getNotif();
    this.localLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.dashboard {
  overflow-x: hidden;
  height: initial;
  @include media-xl('below') {
    height: 100%;
  }
}
.dashboard-card-holder-container {
  @include media-md('below') {
    ::-webkit-scrollbar {
      height: 0.5rem !important;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--primary-light-6) !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--primary) !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--primary-dark-3) !important;
    }
  }

  &-outer {
    overflow-x: hidden;
    @include media-xxl('below') {
      overflow: auto !important;
    }
  }
  &-inner {
    ::-webkit-scrollbar {
      width: 0.5rem !important;
    }
    ::-webkit-scrollbar-track {
      background-color: #ffffff1c !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-dark-2) !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--gray-dark-4) !important;
    }
    @include media-xxl('below') {
      min-width: 1400px;
      height: 100%;
    }
    @include media-md('below') {
      min-width: 300vw;
    }
  }
}
</style>
