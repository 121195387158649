<template>
  <DashboardCardHolder :counter="counter" :title="title" class="h-100">
    <!-- Icon to be used on the header -->
    <template v-slot:prepend-icon>
      <component :is="icon"></component>
    </template>
    <div v-if="items.data && items.data.length > 0">
      <DashboardCard
        class="mb-2"
        v-for="item in items.data"
        :key="`order-card-${type}-${item.id}`"
        :id="item.id"
        :type="type"
        :rqf="item.id"
        :project="item.project_name"
        :delivery_date="item.delivery_date"
        :end_date="item.bidding_end_date"
        :items="item.products"
        :order_date="item.created_at"
        :data="item"
      ></DashboardCard>
      <AppBtn text class="m-1 w-100" :to="seeAllRoute">See More...</AppBtn>
    </div>
    <div
      v-else
      class="h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <SvgNoRequest></SvgNoRequest>
      <p class="mt-2">No Request Yet</p>
    </div>
  </DashboardCardHolder>
</template>

<script>
import DashboardCardHolder from '../../Partial/Dashboard/DashboardCardHolder.vue';
import SvgPendingRequest from './SVGComponent/SvgPendingRequest.vue';
import SvgApprovedQuotation from './SVGComponent/SvgApprovedQuotation.vue';
import SvgApprovedRequests from './SVGComponent/SvgApprovedRequests.vue';
import SvgNoRequest from './SVGComponent/SvgNoRequest.vue';
import DashboardCard from './DashboardCard.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
export default {
  name: 'BuyerDashboardCardHolder',

  components: {
    DashboardCardHolder,
    DashboardCard,
    SvgPendingRequest,
    SvgApprovedQuotation,
    SvgApprovedRequests,
    SvgNoRequest,
    AppBtn,
  },

  props: {
    seeAllRoute: { type: [String, Object], default: null },
    counter: { type: [String, Number], default: 0 },
    items: { type: Object, default: () => ({}) },
    type: {
      type: String,
      default: 'request',
      validator: (value) =>
        ['request', 'quotation', 'approved'].indexOf(value) !== -1,
    },
  },

  computed: {
    title() {
      switch (this.type) {
        case 'approved':
          return 'ACTIVE RFQs';
        case 'quotation':
          return 'APPROVED quotations';
        case 'request':
        default:
          return 'PENDING RFQs';
      }
    },
    icon() {
      switch (this.type) {
        case 'approved':
          return 'SvgApprovedRequests';
        case 'quotation':
          return 'SvgApprovedQuotation';
        case 'request':
        default:
          return 'SvgPendingRequest';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
